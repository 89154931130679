<template>
  <contetnList :key="keyNum" />
</template>

<script>
import contetnList from "./contentList.vue";

export default {
  name: "userLabelList",
  components: {
    contetnList,
  },
  data() {
    return {
      keyNum: 0,
    };
  },
  watch: {
    $route: {
      handler() {
        this.keyNum += 1;
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped></style>
